@charset "UTF-8";
@font-face {
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 300;
  src: url("../documents/fonts/fontawesome-webfont.eot?v=4.7.0");
  src: url("../documents/fonts/fontawesome-webfont.eot?#iefix&v=4.7.0") format("embedded-opentype"), url("../documents/fonts/fontawesome-webfont.woff2?v=4.7.0") format("woff2"), url("../documents/fonts/fontawesome-webfont.woff?v=4.7.0") format("woff"), url("../documents/fonts/fontawesome-webfont.ttf?v=4.7.0") format("truetype"), url("../documents/fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular") format("svg");
  font-display: block;
}
.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* makes the font 33% larger relative to the icon container */
.fa-lg {
  font-size: 1.3333333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-fw {
  width: 1.2857142857em;
  text-align: center;
}

.fa-ul {
  padding-left: 0;
  margin-left: 2.1428571429em;
  list-style-type: none;
}
.fa-ul > li {
  position: relative;
}

.fa-li {
  position: absolute;
  left: -2.1428571429em;
  width: 2.1428571429em;
  top: 0.1428571429em;
  text-align: center;
}
.fa-li.fa-lg {
  left: -1.8571428571em;
}

.fa-border {
  padding: 0.2em 0.25em 0.15em;
  border: solid 0.08em #eee;
  border-radius: 0.1em;
}

.fa-pull-left {
  float: left;
}

.fa-pull-right {
  float: right;
}

.fa.fa-pull-left {
  margin-right: 0.3em;
}
.fa.fa-pull-right {
  margin-left: 0.3em;
}

/* Deprecated as of 4.4.0 */
.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.fa.pull-left {
  margin-right: 0.3em;
}
.fa.pull-right {
  margin-left: 0.3em;
}

.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}

.fa-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
  animation: fa-spin 1s infinite steps(8);
}

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1);
}

:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical {
  filter: none;
}

.fa-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle;
}

.fa-stack-1x, .fa-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: #fff;
}

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
   readers do not read off random characters that represent icons */
.fa-glass:before {
  content: "\f000";
}

.fa-music:before {
  content: "\f001";
}

.fa-search:before {
  content: "\f002";
}

.fa-envelope-o:before {
  content: "\f003";
}

.fa-heart:before {
  content: "\f004";
}

.fa-star:before {
  content: "\f005";
}

.fa-star-o:before {
  content: "\f006";
}

.fa-user:before {
  content: "\f007";
}

.fa-film:before {
  content: "\f008";
}

.fa-th-large:before {
  content: "\f009";
}

.fa-th:before {
  content: "\f00a";
}

.fa-th-list:before {
  content: "\f00b";
}

.fa-check:before {
  content: "\f00c";
}

.fa-remove:before,
.fa-close:before,
.fa-times:before {
  content: "\f00d";
}

.fa-search-plus:before {
  content: "\f00e";
}

.fa-search-minus:before {
  content: "\f010";
}

.fa-power-off:before {
  content: "\f011";
}

.fa-signal:before {
  content: "\f012";
}

.fa-gear:before,
.fa-cog:before {
  content: "\f013";
}

.fa-trash-o:before {
  content: "\f014";
}

.fa-home:before {
  content: "\f015";
}

.fa-file-o:before {
  content: "\f016";
}

.fa-clock-o:before {
  content: "\f017";
}

.fa-road:before {
  content: "\f018";
}

.fa-download:before {
  content: "\f019";
}

.fa-arrow-circle-o-down:before {
  content: "\f01a";
}

.fa-arrow-circle-o-up:before {
  content: "\f01b";
}

.fa-inbox:before {
  content: "\f01c";
}

.fa-play-circle-o:before {
  content: "\f01d";
}

.fa-rotate-right:before,
.fa-repeat:before {
  content: "\f01e";
}

.fa-refresh:before {
  content: "\f021";
}

.fa-list-alt:before {
  content: "\f022";
}

.fa-lock:before {
  content: "\f023";
}

.fa-flag:before {
  content: "\f024";
}

.fa-headphones:before {
  content: "\f025";
}

.fa-volume-off:before {
  content: "\f026";
}

.fa-volume-down:before {
  content: "\f027";
}

.fa-volume-up:before {
  content: "\f028";
}

.fa-qrcode:before {
  content: "\f029";
}

.fa-barcode:before {
  content: "\f02a";
}

.fa-tag:before {
  content: "\f02b";
}

.fa-tags:before {
  content: "\f02c";
}

.fa-book:before {
  content: "\f02d";
}

.fa-bookmark:before {
  content: "\f02e";
}

.fa-print:before {
  content: "\f02f";
}

.fa-camera:before {
  content: "\f030";
}

.fa-font:before {
  content: "\f031";
}

.fa-bold:before {
  content: "\f032";
}

.fa-italic:before {
  content: "\f033";
}

.fa-text-height:before {
  content: "\f034";
}

.fa-text-width:before {
  content: "\f035";
}

.fa-align-left:before {
  content: "\f036";
}

.fa-align-center:before {
  content: "\f037";
}

.fa-align-right:before {
  content: "\f038";
}

.fa-align-justify:before {
  content: "\f039";
}

.fa-list:before {
  content: "\f03a";
}

.fa-dedent:before,
.fa-outdent:before {
  content: "\f03b";
}

.fa-indent:before {
  content: "\f03c";
}

.fa-video-camera:before {
  content: "\f03d";
}

.fa-photo:before,
.fa-image:before,
.fa-picture-o:before {
  content: "\f03e";
}

.fa-pencil:before {
  content: "\f040";
}

.fa-map-marker:before {
  content: "\f041";
}

.fa-adjust:before {
  content: "\f042";
}

.fa-tint:before {
  content: "\f043";
}

.fa-edit:before,
.fa-pencil-square-o:before {
  content: "\f044";
}

.fa-share-square-o:before {
  content: "\f045";
}

.fa-check-square-o:before {
  content: "\f046";
}

.fa-arrows:before {
  content: "\f047";
}

.fa-step-backward:before {
  content: "\f048";
}

.fa-fast-backward:before {
  content: "\f049";
}

.fa-backward:before {
  content: "\f04a";
}

.fa-play:before {
  content: "\f04b";
}

.fa-pause:before {
  content: "\f04c";
}

.fa-stop:before {
  content: "\f04d";
}

.fa-forward:before {
  content: "\f04e";
}

.fa-fast-forward:before {
  content: "\f050";
}

.fa-step-forward:before {
  content: "\f051";
}

.fa-eject:before {
  content: "\f052";
}

.fa-chevron-left:before {
  content: "\f053";
}

.fa-chevron-right:before {
  content: "\f054";
}

.fa-plus-circle:before {
  content: "\f055";
}

.fa-minus-circle:before {
  content: "\f056";
}

.fa-times-circle:before {
  content: "\f057";
}

.fa-check-circle:before {
  content: "\f058";
}

.fa-question-circle:before {
  content: "\f059";
}

.fa-info-circle:before {
  content: "\f05a";
}

.fa-crosshairs:before {
  content: "\f05b";
}

.fa-times-circle-o:before {
  content: "\f05c";
}

.fa-check-circle-o:before {
  content: "\f05d";
}

.fa-ban:before {
  content: "\f05e";
}

.fa-arrow-left:before {
  content: "\f060";
}

.fa-arrow-right:before {
  content: "\f061";
}

.fa-arrow-up:before {
  content: "\f062";
}

.fa-arrow-down:before {
  content: "\f063";
}

.fa-mail-forward:before,
.fa-share:before {
  content: "\f064";
}

.fa-expand:before {
  content: "\f065";
}

.fa-compress:before {
  content: "\f066";
}

.fa-plus:before {
  content: "\f067";
}

.fa-minus:before {
  content: "\f068";
}

.fa-asterisk:before {
  content: "\f069";
}

.fa-exclamation-circle:before {
  content: "\f06a";
}

.fa-gift:before {
  content: "\f06b";
}

.fa-leaf:before {
  content: "\f06c";
}

.fa-fire:before {
  content: "\f06d";
}

.fa-eye:before {
  content: "\f06e";
}

.fa-eye-slash:before {
  content: "\f070";
}

.fa-warning:before,
.fa-exclamation-triangle:before {
  content: "\f071";
}

.fa-plane:before {
  content: "\f072";
}

.fa-calendar:before {
  content: "\f073";
}

.fa-random:before {
  content: "\f074";
}

.fa-comment:before {
  content: "\f075";
}

.fa-magnet:before {
  content: "\f076";
}

.fa-chevron-up:before {
  content: "\f077";
}

.fa-chevron-down:before {
  content: "\f078";
}

.fa-retweet:before {
  content: "\f079";
}

.fa-shopping-cart:before {
  content: "\f07a";
}

.fa-folder:before {
  content: "\f07b";
}

.fa-folder-open:before {
  content: "\f07c";
}

.fa-arrows-v:before {
  content: "\f07d";
}

.fa-arrows-h:before {
  content: "\f07e";
}

.fa-bar-chart-o:before,
.fa-bar-chart:before {
  content: "\f080";
}

.fa-twitter-square:before {
  content: "\f081";
}

.fa-facebook-square:before {
  content: "\f082";
}

.fa-camera-retro:before {
  content: "\f083";
}

.fa-key:before {
  content: "\f084";
}

.fa-gears:before,
.fa-cogs:before {
  content: "\f085";
}

.fa-comments:before {
  content: "\f086";
}

.fa-thumbs-o-up:before {
  content: "\f087";
}

.fa-thumbs-o-down:before {
  content: "\f088";
}

.fa-star-half:before {
  content: "\f089";
}

.fa-heart-o:before {
  content: "\f08a";
}

.fa-sign-out:before {
  content: "\f08b";
}

.fa-linkedin-square:before {
  content: "\f08c";
}

.fa-thumb-tack:before {
  content: "\f08d";
}

.fa-external-link:before {
  content: "\f08e";
}

.fa-sign-in:before {
  content: "\f090";
}

.fa-trophy:before {
  content: "\f091";
}

.fa-github-square:before {
  content: "\f092";
}

.fa-upload:before {
  content: "\f093";
}

.fa-lemon-o:before {
  content: "\f094";
}

.fa-phone:before {
  content: "\f095";
}

.fa-square-o:before {
  content: "\f096";
}

.fa-bookmark-o:before {
  content: "\f097";
}

.fa-phone-square:before {
  content: "\f098";
}

.fa-twitter:before {
  content: "\f099";
}

.fa-facebook-f:before,
.fa-facebook:before {
  content: "\f09a";
}

.fa-github:before {
  content: "\f09b";
}

.fa-unlock:before {
  content: "\f09c";
}

.fa-credit-card:before {
  content: "\f09d";
}

.fa-feed:before,
.fa-rss:before {
  content: "\f09e";
}

.fa-hdd-o:before {
  content: "\f0a0";
}

.fa-bullhorn:before {
  content: "\f0a1";
}

.fa-bell:before {
  content: "\f0f3";
}

.fa-certificate:before {
  content: "\f0a3";
}

.fa-hand-o-right:before {
  content: "\f0a4";
}

.fa-hand-o-left:before {
  content: "\f0a5";
}

.fa-hand-o-up:before {
  content: "\f0a6";
}

.fa-hand-o-down:before {
  content: "\f0a7";
}

.fa-arrow-circle-left:before {
  content: "\f0a8";
}

.fa-arrow-circle-right:before {
  content: "\f0a9";
}

.fa-arrow-circle-up:before {
  content: "\f0aa";
}

.fa-arrow-circle-down:before {
  content: "\f0ab";
}

.fa-globe:before {
  content: "\f0ac";
}

.fa-wrench:before {
  content: "\f0ad";
}

.fa-tasks:before {
  content: "\f0ae";
}

.fa-filter:before {
  content: "\f0b0";
}

.fa-briefcase:before {
  content: "\f0b1";
}

.fa-arrows-alt:before {
  content: "\f0b2";
}

.fa-group:before,
.fa-users:before {
  content: "\f0c0";
}

.fa-chain:before,
.fa-link:before {
  content: "\f0c1";
}

.fa-cloud:before {
  content: "\f0c2";
}

.fa-flask:before {
  content: "\f0c3";
}

.fa-cut:before,
.fa-scissors:before {
  content: "\f0c4";
}

.fa-copy:before,
.fa-files-o:before {
  content: "\f0c5";
}

.fa-paperclip:before {
  content: "\f0c6";
}

.fa-save:before,
.fa-floppy-o:before {
  content: "\f0c7";
}

.fa-square:before {
  content: "\f0c8";
}

.fa-navicon:before,
.fa-reorder:before,
.fa-bars:before {
  content: "\f0c9";
}

.fa-list-ul:before {
  content: "\f0ca";
}

.fa-list-ol:before {
  content: "\f0cb";
}

.fa-strikethrough:before {
  content: "\f0cc";
}

.fa-underline:before {
  content: "\f0cd";
}

.fa-table:before {
  content: "\f0ce";
}

.fa-magic:before {
  content: "\f0d0";
}

.fa-truck:before {
  content: "\f0d1";
}

.fa-pinterest:before {
  content: "\f0d2";
}

.fa-pinterest-square:before {
  content: "\f0d3";
}

.fa-google-plus-square:before {
  content: "\f0d4";
}

.fa-google-plus:before {
  content: "\f0d5";
}

.fa-money:before {
  content: "\f0d6";
}

.fa-caret-down:before {
  content: "\f0d7";
}

.fa-caret-up:before {
  content: "\f0d8";
}

.fa-caret-left:before {
  content: "\f0d9";
}

.fa-caret-right:before {
  content: "\f0da";
}

.fa-columns:before {
  content: "\f0db";
}

.fa-unsorted:before,
.fa-sort:before {
  content: "\f0dc";
}

.fa-sort-down:before,
.fa-sort-desc:before {
  content: "\f0dd";
}

.fa-sort-up:before,
.fa-sort-asc:before {
  content: "\f0de";
}

.fa-envelope:before {
  content: "\f0e0";
}

.fa-linkedin:before {
  content: "\f0e1";
}

.fa-rotate-left:before,
.fa-undo:before {
  content: "\f0e2";
}

.fa-legal:before,
.fa-gavel:before {
  content: "\f0e3";
}

.fa-dashboard:before,
.fa-tachometer:before {
  content: "\f0e4";
}

.fa-comment-o:before {
  content: "\f0e5";
}

.fa-comments-o:before {
  content: "\f0e6";
}

.fa-flash:before,
.fa-bolt:before {
  content: "\f0e7";
}

.fa-sitemap:before {
  content: "\f0e8";
}

.fa-umbrella:before {
  content: "\f0e9";
}

.fa-paste:before,
.fa-clipboard:before {
  content: "\f0ea";
}

.fa-lightbulb-o:before {
  content: "\f0eb";
}

.fa-exchange:before {
  content: "\f0ec";
}

.fa-cloud-download:before {
  content: "\f0ed";
}

.fa-cloud-upload:before {
  content: "\f0ee";
}

.fa-user-md:before {
  content: "\f0f0";
}

.fa-stethoscope:before {
  content: "\f0f1";
}

.fa-suitcase:before {
  content: "\f0f2";
}

.fa-bell-o:before {
  content: "\f0a2";
}

.fa-coffee:before {
  content: "\f0f4";
}

.fa-cutlery:before {
  content: "\f0f5";
}

.fa-file-text-o:before {
  content: "\f0f6";
}

.fa-building-o:before {
  content: "\f0f7";
}

.fa-hospital-o:before {
  content: "\f0f8";
}

.fa-ambulance:before {
  content: "\f0f9";
}

.fa-medkit:before {
  content: "\f0fa";
}

.fa-fighter-jet:before {
  content: "\f0fb";
}

.fa-beer:before {
  content: "\f0fc";
}

.fa-h-square:before {
  content: "\f0fd";
}

.fa-plus-square:before {
  content: "\f0fe";
}

.fa-angle-double-left:before {
  content: "\f100";
}

.fa-angle-double-right:before {
  content: "\f101";
}

.fa-angle-double-up:before {
  content: "\f102";
}

.fa-angle-double-down:before {
  content: "\f103";
}

.fa-angle-left:before {
  content: "\f104";
}

.fa-angle-right:before {
  content: "\f105";
}

.fa-angle-up:before {
  content: "\f106";
}

.fa-angle-down:before {
  content: "\f107";
}

.fa-desktop:before {
  content: "\f108";
}

.fa-laptop:before {
  content: "\f109";
}

.fa-tablet:before {
  content: "\f10a";
}

.fa-mobile-phone:before,
.fa-mobile:before {
  content: "\f10b";
}

.fa-circle-o:before {
  content: "\f10c";
}

.fa-quote-left:before {
  content: "\f10d";
}

.fa-quote-right:before {
  content: "\f10e";
}

.fa-spinner:before {
  content: "\f110";
}

.fa-circle:before, .fa.none:before {
  content: "\f111";
}

.fa-mail-reply:before,
.fa-reply:before {
  content: "\f112";
}

.fa-github-alt:before {
  content: "\f113";
}

.fa-folder-o:before {
  content: "\f114";
}

.fa-folder-open-o:before {
  content: "\f115";
}

.fa-smile-o:before {
  content: "\f118";
}

.fa-frown-o:before {
  content: "\f119";
}

.fa-meh-o:before {
  content: "\f11a";
}

.fa-gamepad:before {
  content: "\f11b";
}

.fa-keyboard-o:before {
  content: "\f11c";
}

.fa-flag-o:before {
  content: "\f11d";
}

.fa-flag-checkered:before {
  content: "\f11e";
}

.fa-terminal:before {
  content: "\f120";
}

.fa-code:before {
  content: "\f121";
}

.fa-mail-reply-all:before,
.fa-reply-all:before {
  content: "\f122";
}

.fa-star-half-empty:before,
.fa-star-half-full:before,
.fa-star-half-o:before {
  content: "\f123";
}

.fa-location-arrow:before {
  content: "\f124";
}

.fa-crop:before {
  content: "\f125";
}

.fa-code-fork:before {
  content: "\f126";
}

.fa-unlink:before,
.fa-chain-broken:before {
  content: "\f127";
}

.fa-question:before {
  content: "\f128";
}

.fa-info:before {
  content: "\f129";
}

.fa-exclamation:before {
  content: "\f12a";
}

.fa-superscript:before {
  content: "\f12b";
}

.fa-subscript:before {
  content: "\f12c";
}

.fa-eraser:before {
  content: "\f12d";
}

.fa-puzzle-piece:before {
  content: "\f12e";
}

.fa-microphone:before {
  content: "\f130";
}

.fa-microphone-slash:before {
  content: "\f131";
}

.fa-shield:before {
  content: "\f132";
}

.fa-calendar-o:before {
  content: "\f133";
}

.fa-fire-extinguisher:before {
  content: "\f134";
}

.fa-rocket:before {
  content: "\f135";
}

.fa-maxcdn:before {
  content: "\f136";
}

.fa-chevron-circle-left:before {
  content: "\f137";
}

.fa-chevron-circle-right:before {
  content: "\f138";
}

.fa-chevron-circle-up:before {
  content: "\f139";
}

.fa-chevron-circle-down:before {
  content: "\f13a";
}

.fa-html5:before {
  content: "\f13b";
}

.fa-css3:before {
  content: "\f13c";
}

.fa-anchor:before {
  content: "\f13d";
}

.fa-unlock-alt:before {
  content: "\f13e";
}

.fa-bullseye:before {
  content: "\f140";
}

.fa-ellipsis-h:before {
  content: "\f141";
}

.fa-ellipsis-v:before {
  content: "\f142";
}

.fa-rss-square:before {
  content: "\f143";
}

.fa-play-circle:before {
  content: "\f144";
}

.fa-ticket:before {
  content: "\f145";
}

.fa-minus-square:before {
  content: "\f146";
}

.fa-minus-square-o:before {
  content: "\f147";
}

.fa-level-up:before {
  content: "\f148";
}

.fa-level-down:before {
  content: "\f149";
}

.fa-check-square:before {
  content: "\f14a";
}

.fa-pencil-square:before {
  content: "\f14b";
}

.fa-external-link-square:before {
  content: "\f14c";
}

.fa-share-square:before {
  content: "\f14d";
}

.fa-compass:before {
  content: "\f14e";
}

.fa-toggle-down:before,
.fa-caret-square-o-down:before {
  content: "\f150";
}

.fa-toggle-up:before,
.fa-caret-square-o-up:before {
  content: "\f151";
}

.fa-toggle-right:before,
.fa-caret-square-o-right:before {
  content: "\f152";
}

.fa-euro:before,
.fa-eur:before {
  content: "\f153";
}

.fa-gbp:before {
  content: "\f154";
}

.fa-dollar:before,
.fa-usd:before {
  content: "\f155";
}

.fa-rupee:before,
.fa-inr:before {
  content: "\f156";
}

.fa-cny:before,
.fa-rmb:before,
.fa-yen:before,
.fa-jpy:before {
  content: "\f157";
}

.fa-ruble:before,
.fa-rouble:before,
.fa-rub:before {
  content: "\f158";
}

.fa-won:before,
.fa-krw:before {
  content: "\f159";
}

.fa-bitcoin:before,
.fa-btc:before {
  content: "\f15a";
}

.fa-file:before {
  content: "\f15b";
}

.fa-file-text:before {
  content: "\f15c";
}

.fa-sort-alpha-asc:before {
  content: "\f15d";
}

.fa-sort-alpha-desc:before {
  content: "\f15e";
}

.fa-sort-amount-asc:before {
  content: "\f160";
}

.fa-sort-amount-desc:before {
  content: "\f161";
}

.fa-sort-numeric-asc:before {
  content: "\f162";
}

.fa-sort-numeric-desc:before {
  content: "\f163";
}

.fa-thumbs-up:before {
  content: "\f164";
}

.fa-thumbs-down:before {
  content: "\f165";
}

.fa-youtube-square:before {
  content: "\f166";
}

.fa-youtube:before {
  content: "\f167";
}

.fa-xing:before {
  content: "\f168";
}

.fa-xing-square:before {
  content: "\f169";
}

.fa-youtube-play:before {
  content: "\f16a";
}

.fa-dropbox:before {
  content: "\f16b";
}

.fa-stack-overflow:before {
  content: "\f16c";
}

.fa-instagram:before {
  content: "\f16d";
}

.fa-flickr:before {
  content: "\f16e";
}

.fa-adn:before {
  content: "\f170";
}

.fa-bitbucket:before {
  content: "\f171";
}

.fa-bitbucket-square:before {
  content: "\f172";
}

.fa-tumblr:before {
  content: "\f173";
}

.fa-tumblr-square:before {
  content: "\f174";
}

.fa-long-arrow-down:before {
  content: "\f175";
}

.fa-long-arrow-up:before {
  content: "\f176";
}

.fa-long-arrow-left:before {
  content: "\f177";
}

.fa-long-arrow-right:before {
  content: "\f178";
}

.fa-apple:before {
  content: "\f179";
}

.fa-windows:before {
  content: "\f17a";
}

.fa-android:before {
  content: "\f17b";
}

.fa-linux:before {
  content: "\f17c";
}

.fa-dribbble:before {
  content: "\f17d";
}

.fa-skype:before {
  content: "\f17e";
}

.fa-foursquare:before {
  content: "\f180";
}

.fa-trello:before {
  content: "\f181";
}

.fa-female:before {
  content: "\f182";
}

.fa-male:before {
  content: "\f183";
}

.fa-gittip:before,
.fa-gratipay:before {
  content: "\f184";
}

.fa-sun-o:before {
  content: "\f185";
}

.fa-moon-o:before {
  content: "\f186";
}

.fa-archive:before {
  content: "\f187";
}

.fa-bug:before {
  content: "\f188";
}

.fa-vk:before {
  content: "\f189";
}

.fa-weibo:before {
  content: "\f18a";
}

.fa-renren:before {
  content: "\f18b";
}

.fa-pagelines:before {
  content: "\f18c";
}

.fa-stack-exchange:before {
  content: "\f18d";
}

.fa-arrow-circle-o-right:before {
  content: "\f18e";
}

.fa-arrow-circle-o-left:before {
  content: "\f190";
}

.fa-toggle-left:before,
.fa-caret-square-o-left:before {
  content: "\f191";
}

.fa-dot-circle-o:before {
  content: "\f192";
}

.fa-wheelchair:before {
  content: "\f193";
}

.fa-vimeo-square:before {
  content: "\f194";
}

.fa-turkish-lira:before,
.fa-try:before {
  content: "\f195";
}

.fa-plus-square-o:before {
  content: "\f196";
}

.fa-space-shuttle:before {
  content: "\f197";
}

.fa-slack:before {
  content: "\f198";
}

.fa-envelope-square:before {
  content: "\f199";
}

.fa-wordpress:before {
  content: "\f19a";
}

.fa-openid:before {
  content: "\f19b";
}

.fa-institution:before,
.fa-bank:before,
.fa-university:before {
  content: "\f19c";
}

.fa-mortar-board:before,
.fa-graduation-cap:before {
  content: "\f19d";
}

.fa-yahoo:before {
  content: "\f19e";
}

.fa-google:before {
  content: "\f1a0";
}

.fa-reddit:before {
  content: "\f1a1";
}

.fa-reddit-square:before {
  content: "\f1a2";
}

.fa-stumbleupon-circle:before {
  content: "\f1a3";
}

.fa-stumbleupon:before {
  content: "\f1a4";
}

.fa-delicious:before {
  content: "\f1a5";
}

.fa-digg:before {
  content: "\f1a6";
}

.fa-pied-piper-pp:before {
  content: "\f1a7";
}

.fa-pied-piper-alt:before {
  content: "\f1a8";
}

.fa-drupal:before {
  content: "\f1a9";
}

.fa-joomla:before {
  content: "\f1aa";
}

.fa-language:before {
  content: "\f1ab";
}

.fa-fax:before {
  content: "\f1ac";
}

.fa-building:before {
  content: "\f1ad";
}

.fa-child:before {
  content: "\f1ae";
}

.fa-paw:before {
  content: "\f1b0";
}

.fa-spoon:before {
  content: "\f1b1";
}

.fa-cube:before {
  content: "\f1b2";
}

.fa-cubes:before {
  content: "\f1b3";
}

.fa-behance:before {
  content: "\f1b4";
}

.fa-behance-square:before {
  content: "\f1b5";
}

.fa-steam:before {
  content: "\f1b6";
}

.fa-steam-square:before {
  content: "\f1b7";
}

.fa-recycle:before {
  content: "\f1b8";
}

.fa-automobile:before,
.fa-car:before {
  content: "\f1b9";
}

.fa-cab:before,
.fa-taxi:before {
  content: "\f1ba";
}

.fa-tree:before {
  content: "\f1bb";
}

.fa-spotify:before {
  content: "\f1bc";
}

.fa-deviantart:before {
  content: "\f1bd";
}

.fa-soundcloud:before {
  content: "\f1be";
}

.fa-database:before {
  content: "\f1c0";
}

.fa-file-pdf-o:before {
  content: "\f1c1";
}

.fa-file-word-o:before {
  content: "\f1c2";
}

.fa-file-excel-o:before {
  content: "\f1c3";
}

.fa-file-powerpoint-o:before {
  content: "\f1c4";
}

.fa-file-photo-o:before,
.fa-file-picture-o:before,
.fa-file-image-o:before {
  content: "\f1c5";
}

.fa-file-zip-o:before,
.fa-file-archive-o:before {
  content: "\f1c6";
}

.fa-file-sound-o:before,
.fa-file-audio-o:before {
  content: "\f1c7";
}

.fa-file-movie-o:before,
.fa-file-video-o:before {
  content: "\f1c8";
}

.fa-file-code-o:before {
  content: "\f1c9";
}

.fa-vine:before {
  content: "\f1ca";
}

.fa-codepen:before {
  content: "\f1cb";
}

.fa-jsfiddle:before {
  content: "\f1cc";
}

.fa-life-bouy:before,
.fa-life-buoy:before,
.fa-life-saver:before,
.fa-support:before,
.fa-life-ring:before {
  content: "\f1cd";
}

.fa-circle-o-notch:before {
  content: "\f1ce";
}

.fa-ra:before,
.fa-resistance:before,
.fa-rebel:before {
  content: "\f1d0";
}

.fa-ge:before,
.fa-empire:before {
  content: "\f1d1";
}

.fa-git-square:before {
  content: "\f1d2";
}

.fa-git:before {
  content: "\f1d3";
}

.fa-y-combinator-square:before,
.fa-yc-square:before,
.fa-hacker-news:before {
  content: "\f1d4";
}

.fa-tencent-weibo:before {
  content: "\f1d5";
}

.fa-qq:before {
  content: "\f1d6";
}

.fa-wechat:before,
.fa-weixin:before {
  content: "\f1d7";
}

.fa-send:before,
.fa-paper-plane:before {
  content: "\f1d8";
}

.fa-send-o:before,
.fa-paper-plane-o:before {
  content: "\f1d9";
}

.fa-history:before {
  content: "\f1da";
}

.fa-circle-thin:before {
  content: "\f1db";
}

.fa-header:before {
  content: "\f1dc";
}

.fa-paragraph:before {
  content: "\f1dd";
}

.fa-sliders:before {
  content: "\f1de";
}

.fa-share-alt:before {
  content: "\f1e0";
}

.fa-share-alt-square:before {
  content: "\f1e1";
}

.fa-bomb:before {
  content: "\f1e2";
}

.fa-soccer-ball-o:before,
.fa-futbol-o:before {
  content: "\f1e3";
}

.fa-tty:before {
  content: "\f1e4";
}

.fa-binoculars:before {
  content: "\f1e5";
}

.fa-plug:before {
  content: "\f1e6";
}

.fa-slideshare:before {
  content: "\f1e7";
}

.fa-twitch:before {
  content: "\f1e8";
}

.fa-yelp:before {
  content: "\f1e9";
}

.fa-newspaper-o:before {
  content: "\f1ea";
}

.fa-wifi:before {
  content: "\f1eb";
}

.fa-calculator:before {
  content: "\f1ec";
}

.fa-paypal:before {
  content: "\f1ed";
}

.fa-google-wallet:before {
  content: "\f1ee";
}

.fa-cc-visa:before {
  content: "\f1f0";
}

.fa-cc-mastercard:before {
  content: "\f1f1";
}

.fa-cc-discover:before {
  content: "\f1f2";
}

.fa-cc-amex:before {
  content: "\f1f3";
}

.fa-cc-paypal:before {
  content: "\f1f4";
}

.fa-cc-stripe:before {
  content: "\f1f5";
}

.fa-bell-slash:before {
  content: "\f1f6";
}

.fa-bell-slash-o:before {
  content: "\f1f7";
}

.fa-trash:before {
  content: "\f1f8";
}

.fa-copyright:before {
  content: "\f1f9";
}

.fa-at:before {
  content: "\f1fa";
}

.fa-eyedropper:before {
  content: "\f1fb";
}

.fa-paint-brush:before {
  content: "\f1fc";
}

.fa-birthday-cake:before {
  content: "\f1fd";
}

.fa-area-chart:before {
  content: "\f1fe";
}

.fa-pie-chart:before {
  content: "\f200";
}

.fa-line-chart:before {
  content: "\f201";
}

.fa-lastfm:before {
  content: "\f202";
}

.fa-lastfm-square:before {
  content: "\f203";
}

.fa-toggle-off:before {
  content: "\f204";
}

.fa-toggle-on:before {
  content: "\f205";
}

.fa-bicycle:before {
  content: "\f206";
}

.fa-bus:before {
  content: "\f207";
}

.fa-ioxhost:before {
  content: "\f208";
}

.fa-angellist:before {
  content: "\f209";
}

.fa-cc:before {
  content: "\f20a";
}

.fa-shekel:before,
.fa-sheqel:before,
.fa-ils:before {
  content: "\f20b";
}

.fa-meanpath:before {
  content: "\f20c";
}

.fa-buysellads:before {
  content: "\f20d";
}

.fa-connectdevelop:before {
  content: "\f20e";
}

.fa-dashcube:before {
  content: "\f210";
}

.fa-forumbee:before {
  content: "\f211";
}

.fa-leanpub:before {
  content: "\f212";
}

.fa-sellsy:before {
  content: "\f213";
}

.fa-shirtsinbulk:before {
  content: "\f214";
}

.fa-simplybuilt:before {
  content: "\f215";
}

.fa-skyatlas:before {
  content: "\f216";
}

.fa-cart-plus:before {
  content: "\f217";
}

.fa-cart-arrow-down:before {
  content: "\f218";
}

.fa-diamond:before {
  content: "\f219";
}

.fa-ship:before {
  content: "\f21a";
}

.fa-user-secret:before {
  content: "\f21b";
}

.fa-motorcycle:before {
  content: "\f21c";
}

.fa-street-view:before {
  content: "\f21d";
}

.fa-heartbeat:before {
  content: "\f21e";
}

.fa-venus:before {
  content: "\f221";
}

.fa-mars:before {
  content: "\f222";
}

.fa-mercury:before {
  content: "\f223";
}

.fa-intersex:before,
.fa-transgender:before {
  content: "\f224";
}

.fa-transgender-alt:before {
  content: "\f225";
}

.fa-venus-double:before {
  content: "\f226";
}

.fa-mars-double:before {
  content: "\f227";
}

.fa-venus-mars:before {
  content: "\f228";
}

.fa-mars-stroke:before {
  content: "\f229";
}

.fa-mars-stroke-v:before {
  content: "\f22a";
}

.fa-mars-stroke-h:before {
  content: "\f22b";
}

.fa-neuter:before {
  content: "\f22c";
}

.fa-genderless:before {
  content: "\f22d";
}

.fa-facebook-official:before {
  content: "\f230";
}

.fa-pinterest-p:before {
  content: "\f231";
}

.fa-whatsapp:before {
  content: "\f232";
}

.fa-server:before {
  content: "\f233";
}

.fa-user-plus:before {
  content: "\f234";
}

.fa-user-times:before {
  content: "\f235";
}

.fa-hotel:before,
.fa-bed:before {
  content: "\f236";
}

.fa-viacoin:before {
  content: "\f237";
}

.fa-train:before {
  content: "\f238";
}

.fa-subway:before {
  content: "\f239";
}

.fa-medium:before {
  content: "\f23a";
}

.fa-yc:before,
.fa-y-combinator:before {
  content: "\f23b";
}

.fa-optin-monster:before {
  content: "\f23c";
}

.fa-opencart:before {
  content: "\f23d";
}

.fa-expeditedssl:before {
  content: "\f23e";
}

.fa-battery-4:before,
.fa-battery:before,
.fa-battery-full:before {
  content: "\f240";
}

.fa-battery-3:before,
.fa-battery-three-quarters:before {
  content: "\f241";
}

.fa-battery-2:before,
.fa-battery-half:before {
  content: "\f242";
}

.fa-battery-1:before,
.fa-battery-quarter:before {
  content: "\f243";
}

.fa-battery-0:before,
.fa-battery-empty:before {
  content: "\f244";
}

.fa-mouse-pointer:before {
  content: "\f245";
}

.fa-i-cursor:before {
  content: "\f246";
}

.fa-object-group:before {
  content: "\f247";
}

.fa-object-ungroup:before {
  content: "\f248";
}

.fa-sticky-note:before {
  content: "\f249";
}

.fa-sticky-note-o:before {
  content: "\f24a";
}

.fa-cc-jcb:before {
  content: "\f24b";
}

.fa-cc-diners-club:before {
  content: "\f24c";
}

.fa-clone:before {
  content: "\f24d";
}

.fa-balance-scale:before {
  content: "\f24e";
}

.fa-hourglass-o:before {
  content: "\f250";
}

.fa-hourglass-1:before,
.fa-hourglass-start:before {
  content: "\f251";
}

.fa-hourglass-2:before,
.fa-hourglass-half:before {
  content: "\f252";
}

.fa-hourglass-3:before,
.fa-hourglass-end:before {
  content: "\f253";
}

.fa-hourglass:before {
  content: "\f254";
}

.fa-hand-grab-o:before,
.fa-hand-rock-o:before {
  content: "\f255";
}

.fa-hand-stop-o:before,
.fa-hand-paper-o:before {
  content: "\f256";
}

.fa-hand-scissors-o:before {
  content: "\f257";
}

.fa-hand-lizard-o:before {
  content: "\f258";
}

.fa-hand-spock-o:before {
  content: "\f259";
}

.fa-hand-pointer-o:before {
  content: "\f25a";
}

.fa-hand-peace-o:before {
  content: "\f25b";
}

.fa-trademark:before {
  content: "\f25c";
}

.fa-registered:before {
  content: "\f25d";
}

.fa-creative-commons:before {
  content: "\f25e";
}

.fa-gg:before {
  content: "\f260";
}

.fa-gg-circle:before {
  content: "\f261";
}

.fa-tripadvisor:before {
  content: "\f262";
}

.fa-odnoklassniki:before {
  content: "\f263";
}

.fa-odnoklassniki-square:before {
  content: "\f264";
}

.fa-get-pocket:before {
  content: "\f265";
}

.fa-wikipedia-w:before {
  content: "\f266";
}

.fa-safari:before {
  content: "\f267";
}

.fa-chrome:before {
  content: "\f268";
}

.fa-firefox:before {
  content: "\f269";
}

.fa-opera:before {
  content: "\f26a";
}

.fa-internet-explorer:before {
  content: "\f26b";
}

.fa-tv:before,
.fa-television:before {
  content: "\f26c";
}

.fa-contao:before {
  content: "\f26d";
}

.fa-500px:before {
  content: "\f26e";
}

.fa-amazon:before {
  content: "\f270";
}

.fa-calendar-plus-o:before {
  content: "\f271";
}

.fa-calendar-minus-o:before {
  content: "\f272";
}

.fa-calendar-times-o:before {
  content: "\f273";
}

.fa-calendar-check-o:before {
  content: "\f274";
}

.fa-industry:before {
  content: "\f275";
}

.fa-map-pin:before {
  content: "\f276";
}

.fa-map-signs:before {
  content: "\f277";
}

.fa-map-o:before {
  content: "\f278";
}

.fa-map:before {
  content: "\f279";
}

.fa-commenting:before {
  content: "\f27a";
}

.fa-commenting-o:before {
  content: "\f27b";
}

.fa-houzz:before {
  content: "\f27c";
}

.fa-vimeo:before {
  content: "\f27d";
}

.fa-black-tie:before {
  content: "\f27e";
}

.fa-fonticons:before {
  content: "\f280";
}

.fa-reddit-alien:before {
  content: "\f281";
}

.fa-edge:before {
  content: "\f282";
}

.fa-credit-card-alt:before {
  content: "\f283";
}

.fa-codiepie:before {
  content: "\f284";
}

.fa-modx:before {
  content: "\f285";
}

.fa-fort-awesome:before {
  content: "\f286";
}

.fa-usb:before {
  content: "\f287";
}

.fa-product-hunt:before {
  content: "\f288";
}

.fa-mixcloud:before {
  content: "\f289";
}

.fa-scribd:before {
  content: "\f28a";
}

.fa-pause-circle:before {
  content: "\f28b";
}

.fa-pause-circle-o:before {
  content: "\f28c";
}

.fa-stop-circle:before {
  content: "\f28d";
}

.fa-stop-circle-o:before {
  content: "\f28e";
}

.fa-shopping-bag:before {
  content: "\f290";
}

.fa-shopping-basket:before {
  content: "\f291";
}

.fa-hashtag:before {
  content: "\f292";
}

.fa-bluetooth:before {
  content: "\f293";
}

.fa-bluetooth-b:before {
  content: "\f294";
}

.fa-percent:before {
  content: "\f295";
}

.fa-gitlab:before {
  content: "\f296";
}

.fa-wpbeginner:before {
  content: "\f297";
}

.fa-wpforms:before {
  content: "\f298";
}

.fa-envira:before {
  content: "\f299";
}

.fa-universal-access:before {
  content: "\f29a";
}

.fa-wheelchair-alt:before {
  content: "\f29b";
}

.fa-question-circle-o:before {
  content: "\f29c";
}

.fa-blind:before {
  content: "\f29d";
}

.fa-audio-description:before {
  content: "\f29e";
}

.fa-volume-control-phone:before {
  content: "\f2a0";
}

.fa-braille:before {
  content: "\f2a1";
}

.fa-assistive-listening-systems:before {
  content: "\f2a2";
}

.fa-asl-interpreting:before,
.fa-american-sign-language-interpreting:before {
  content: "\f2a3";
}

.fa-deafness:before,
.fa-hard-of-hearing:before,
.fa-deaf:before {
  content: "\f2a4";
}

.fa-glide:before {
  content: "\f2a5";
}

.fa-glide-g:before {
  content: "\f2a6";
}

.fa-signing:before,
.fa-sign-language:before {
  content: "\f2a7";
}

.fa-low-vision:before {
  content: "\f2a8";
}

.fa-viadeo:before {
  content: "\f2a9";
}

.fa-viadeo-square:before {
  content: "\f2aa";
}

.fa-snapchat:before {
  content: "\f2ab";
}

.fa-snapchat-ghost:before {
  content: "\f2ac";
}

.fa-snapchat-square:before {
  content: "\f2ad";
}

.fa-pied-piper:before {
  content: "\f2ae";
}

.fa-first-order:before {
  content: "\f2b0";
}

.fa-yoast:before {
  content: "\f2b1";
}

.fa-themeisle:before {
  content: "\f2b2";
}

.fa-google-plus-circle:before,
.fa-google-plus-official:before {
  content: "\f2b3";
}

.fa-fa:before,
.fa-font-awesome:before {
  content: "\f2b4";
}

.fa-handshake-o:before {
  content: "\f2b5";
}

.fa-envelope-open:before {
  content: "\f2b6";
}

.fa-envelope-open-o:before {
  content: "\f2b7";
}

.fa-linode:before {
  content: "\f2b8";
}

.fa-address-book:before {
  content: "\f2b9";
}

.fa-address-book-o:before {
  content: "\f2ba";
}

.fa-vcard:before,
.fa-address-card:before {
  content: "\f2bb";
}

.fa-vcard-o:before,
.fa-address-card-o:before {
  content: "\f2bc";
}

.fa-user-circle:before {
  content: "\f2bd";
}

.fa-user-circle-o:before {
  content: "\f2be";
}

.fa-user-o:before {
  content: "\f2c0";
}

.fa-id-badge:before {
  content: "\f2c1";
}

.fa-drivers-license:before,
.fa-id-card:before {
  content: "\f2c2";
}

.fa-drivers-license-o:before,
.fa-id-card-o:before {
  content: "\f2c3";
}

.fa-quora:before {
  content: "\f2c4";
}

.fa-free-code-camp:before {
  content: "\f2c5";
}

.fa-telegram:before {
  content: "\f2c6";
}

.fa-thermometer-4:before,
.fa-thermometer:before,
.fa-thermometer-full:before {
  content: "\f2c7";
}

.fa-thermometer-3:before,
.fa-thermometer-three-quarters:before {
  content: "\f2c8";
}

.fa-thermometer-2:before,
.fa-thermometer-half:before {
  content: "\f2c9";
}

.fa-thermometer-1:before,
.fa-thermometer-quarter:before {
  content: "\f2ca";
}

.fa-thermometer-0:before,
.fa-thermometer-empty:before {
  content: "\f2cb";
}

.fa-shower:before {
  content: "\f2cc";
}

.fa-bathtub:before,
.fa-s15:before,
.fa-bath:before {
  content: "\f2cd";
}

.fa-podcast:before {
  content: "\f2ce";
}

.fa-window-maximize:before {
  content: "\f2d0";
}

.fa-window-minimize:before {
  content: "\f2d1";
}

.fa-window-restore:before {
  content: "\f2d2";
}

.fa-times-rectangle:before,
.fa-window-close:before {
  content: "\f2d3";
}

.fa-times-rectangle-o:before,
.fa-window-close-o:before {
  content: "\f2d4";
}

.fa-bandcamp:before {
  content: "\f2d5";
}

.fa-grav:before {
  content: "\f2d6";
}

.fa-etsy:before {
  content: "\f2d7";
}

.fa-imdb:before {
  content: "\f2d8";
}

.fa-ravelry:before {
  content: "\f2d9";
}

.fa-eercast:before {
  content: "\f2da";
}

.fa-microchip:before {
  content: "\f2db";
}

.fa-snowflake-o:before {
  content: "\f2dc";
}

.fa-superpowers:before {
  content: "\f2dd";
}

.fa-wpexplorer:before {
  content: "\f2de";
}

.fa-meetup:before {
  content: "\f2e0";
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}

.flag-icon-background, .flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.flag-icon {
  position: relative;
  display: inline-block;
  width: 1.3333333333em;
  line-height: 1em;
}
.flag-icon:before {
  content: " ";
}
.flag-icon.flag-icon-squared {
  width: 1em;
}

.flag-icon-ad {
  background-image: url(../images/flags/4x3/ad.svg);
}
.flag-icon-ad.flag-icon-squared {
  background-image: url(../images/flags/1x1/ad.svg);
}

.flag-icon-ae {
  background-image: url(../images/flags/4x3/ae.svg);
}
.flag-icon-ae.flag-icon-squared {
  background-image: url(../images/flags/1x1/ae.svg);
}

.flag-icon-af {
  background-image: url(../images/flags/4x3/af.svg);
}
.flag-icon-af.flag-icon-squared {
  background-image: url(../images/flags/1x1/af.svg);
}

.flag-icon-ag {
  background-image: url(../images/flags/4x3/ag.svg);
}
.flag-icon-ag.flag-icon-squared {
  background-image: url(../images/flags/1x1/ag.svg);
}

.flag-icon-ai {
  background-image: url(../images/flags/4x3/ai.svg);
}
.flag-icon-ai.flag-icon-squared {
  background-image: url(../images/flags/1x1/ai.svg);
}

.flag-icon-al {
  background-image: url(../images/flags/4x3/al.svg);
}
.flag-icon-al.flag-icon-squared {
  background-image: url(../images/flags/1x1/al.svg);
}

.flag-icon-am {
  background-image: url(../images/flags/4x3/am.svg);
}
.flag-icon-am.flag-icon-squared {
  background-image: url(../images/flags/1x1/am.svg);
}

.flag-icon-ao {
  background-image: url(../images/flags/4x3/ao.svg);
}
.flag-icon-ao.flag-icon-squared {
  background-image: url(../images/flags/1x1/ao.svg);
}

.flag-icon-aq {
  background-image: url(../images/flags/4x3/aq.svg);
}
.flag-icon-aq.flag-icon-squared {
  background-image: url(../images/flags/1x1/aq.svg);
}

.flag-icon-ar {
  background-image: url(../images/flags/4x3/ar.svg);
}
.flag-icon-ar.flag-icon-squared {
  background-image: url(../images/flags/1x1/ar.svg);
}

.flag-icon-as {
  background-image: url(../images/flags/4x3/as.svg);
}
.flag-icon-as.flag-icon-squared {
  background-image: url(../images/flags/1x1/as.svg);
}

.flag-icon-at {
  background-image: url(../images/flags/4x3/at.svg);
}
.flag-icon-at.flag-icon-squared {
  background-image: url(../images/flags/1x1/at.svg);
}

.flag-icon-au {
  background-image: url(../images/flags/4x3/au.svg);
}
.flag-icon-au.flag-icon-squared {
  background-image: url(../images/flags/1x1/au.svg);
}

.flag-icon-aw {
  background-image: url(../images/flags/4x3/aw.svg);
}
.flag-icon-aw.flag-icon-squared {
  background-image: url(../images/flags/1x1/aw.svg);
}

.flag-icon-ax {
  background-image: url(../images/flags/4x3/ax.svg);
}
.flag-icon-ax.flag-icon-squared {
  background-image: url(../images/flags/1x1/ax.svg);
}

.flag-icon-az {
  background-image: url(../images/flags/4x3/az.svg);
}
.flag-icon-az.flag-icon-squared {
  background-image: url(../images/flags/1x1/az.svg);
}

.flag-icon-ba {
  background-image: url(../images/flags/4x3/ba.svg);
}
.flag-icon-ba.flag-icon-squared {
  background-image: url(../images/flags/1x1/ba.svg);
}

.flag-icon-bb {
  background-image: url(../images/flags/4x3/bb.svg);
}
.flag-icon-bb.flag-icon-squared {
  background-image: url(../images/flags/1x1/bb.svg);
}

.flag-icon-bd {
  background-image: url(../images/flags/4x3/bd.svg);
}
.flag-icon-bd.flag-icon-squared {
  background-image: url(../images/flags/1x1/bd.svg);
}

.flag-icon-be {
  background-image: url(../images/flags/4x3/be.svg);
}
.flag-icon-be.flag-icon-squared {
  background-image: url(../images/flags/1x1/be.svg);
}

.flag-icon-bf {
  background-image: url(../images/flags/4x3/bf.svg);
}
.flag-icon-bf.flag-icon-squared {
  background-image: url(../images/flags/1x1/bf.svg);
}

.flag-icon-bg {
  background-image: url(../images/flags/4x3/bg.svg);
}
.flag-icon-bg.flag-icon-squared {
  background-image: url(../images/flags/1x1/bg.svg);
}

.flag-icon-bh {
  background-image: url(../images/flags/4x3/bh.svg);
}
.flag-icon-bh.flag-icon-squared {
  background-image: url(../images/flags/1x1/bh.svg);
}

.flag-icon-bi {
  background-image: url(../images/flags/4x3/bi.svg);
}
.flag-icon-bi.flag-icon-squared {
  background-image: url(../images/flags/1x1/bi.svg);
}

.flag-icon-bj {
  background-image: url(../images/flags/4x3/bj.svg);
}
.flag-icon-bj.flag-icon-squared {
  background-image: url(../images/flags/1x1/bj.svg);
}

.flag-icon-bl {
  background-image: url(../images/flags/4x3/bl.svg);
}
.flag-icon-bl.flag-icon-squared {
  background-image: url(../images/flags/1x1/bl.svg);
}

.flag-icon-bm {
  background-image: url(../images/flags/4x3/bm.svg);
}
.flag-icon-bm.flag-icon-squared {
  background-image: url(../images/flags/1x1/bm.svg);
}

.flag-icon-bn {
  background-image: url(../images/flags/4x3/bn.svg);
}
.flag-icon-bn.flag-icon-squared {
  background-image: url(../images/flags/1x1/bn.svg);
}

.flag-icon-bo {
  background-image: url(../images/flags/4x3/bo.svg);
}
.flag-icon-bo.flag-icon-squared {
  background-image: url(../images/flags/1x1/bo.svg);
}

.flag-icon-bq {
  background-image: url(../images/flags/4x3/bq.svg);
}
.flag-icon-bq.flag-icon-squared {
  background-image: url(../images/flags/1x1/bq.svg);
}

.flag-icon-br {
  background-image: url(../images/flags/4x3/br.svg);
}
.flag-icon-br.flag-icon-squared {
  background-image: url(../images/flags/1x1/br.svg);
}

.flag-icon-bs {
  background-image: url(../images/flags/4x3/bs.svg);
}
.flag-icon-bs.flag-icon-squared {
  background-image: url(../images/flags/1x1/bs.svg);
}

.flag-icon-bt {
  background-image: url(../images/flags/4x3/bt.svg);
}
.flag-icon-bt.flag-icon-squared {
  background-image: url(../images/flags/1x1/bt.svg);
}

.flag-icon-bv {
  background-image: url(../images/flags/4x3/bv.svg);
}
.flag-icon-bv.flag-icon-squared {
  background-image: url(../images/flags/1x1/bv.svg);
}

.flag-icon-bw {
  background-image: url(../images/flags/4x3/bw.svg);
}
.flag-icon-bw.flag-icon-squared {
  background-image: url(../images/flags/1x1/bw.svg);
}

.flag-icon-by {
  background-image: url(../images/flags/4x3/by.svg);
}
.flag-icon-by.flag-icon-squared {
  background-image: url(../images/flags/1x1/by.svg);
}

.flag-icon-bz {
  background-image: url(../images/flags/4x3/bz.svg);
}
.flag-icon-bz.flag-icon-squared {
  background-image: url(../images/flags/1x1/bz.svg);
}

.flag-icon-ca {
  background-image: url(../images/flags/4x3/ca.svg);
}
.flag-icon-ca.flag-icon-squared {
  background-image: url(../images/flags/1x1/ca.svg);
}

.flag-icon-cc {
  background-image: url(../images/flags/4x3/cc.svg);
}
.flag-icon-cc.flag-icon-squared {
  background-image: url(../images/flags/1x1/cc.svg);
}

.flag-icon-cd {
  background-image: url(../images/flags/4x3/cd.svg);
}
.flag-icon-cd.flag-icon-squared {
  background-image: url(../images/flags/1x1/cd.svg);
}

.flag-icon-cf {
  background-image: url(../images/flags/4x3/cf.svg);
}
.flag-icon-cf.flag-icon-squared {
  background-image: url(../images/flags/1x1/cf.svg);
}

.flag-icon-cg {
  background-image: url(../images/flags/4x3/cg.svg);
}
.flag-icon-cg.flag-icon-squared {
  background-image: url(../images/flags/1x1/cg.svg);
}

.flag-icon-ch {
  background-image: url(../images/flags/4x3/ch.svg);
}
.flag-icon-ch.flag-icon-squared {
  background-image: url(../images/flags/1x1/ch.svg);
}

.flag-icon-ci {
  background-image: url(../images/flags/4x3/ci.svg);
}
.flag-icon-ci.flag-icon-squared {
  background-image: url(../images/flags/1x1/ci.svg);
}

.flag-icon-ck {
  background-image: url(../images/flags/4x3/ck.svg);
}
.flag-icon-ck.flag-icon-squared {
  background-image: url(../images/flags/1x1/ck.svg);
}

.flag-icon-cl {
  background-image: url(../images/flags/4x3/cl.svg);
}
.flag-icon-cl.flag-icon-squared {
  background-image: url(../images/flags/1x1/cl.svg);
}

.flag-icon-cm {
  background-image: url(../images/flags/4x3/cm.svg);
}
.flag-icon-cm.flag-icon-squared {
  background-image: url(../images/flags/1x1/cm.svg);
}

.flag-icon-cn {
  background-image: url(../images/flags/4x3/cn.svg);
}
.flag-icon-cn.flag-icon-squared {
  background-image: url(../images/flags/1x1/cn.svg);
}

.flag-icon-co {
  background-image: url(../images/flags/4x3/co.svg);
}
.flag-icon-co.flag-icon-squared {
  background-image: url(../images/flags/1x1/co.svg);
}

.flag-icon-cr {
  background-image: url(../images/flags/4x3/cr.svg);
}
.flag-icon-cr.flag-icon-squared {
  background-image: url(../images/flags/1x1/cr.svg);
}

.flag-icon-cu {
  background-image: url(../images/flags/4x3/cu.svg);
}
.flag-icon-cu.flag-icon-squared {
  background-image: url(../images/flags/1x1/cu.svg);
}

.flag-icon-cv {
  background-image: url(../images/flags/4x3/cv.svg);
}
.flag-icon-cv.flag-icon-squared {
  background-image: url(../images/flags/1x1/cv.svg);
}

.flag-icon-cw {
  background-image: url(../images/flags/4x3/cw.svg);
}
.flag-icon-cw.flag-icon-squared {
  background-image: url(../images/flags/1x1/cw.svg);
}

.flag-icon-cx {
  background-image: url(../images/flags/4x3/cx.svg);
}
.flag-icon-cx.flag-icon-squared {
  background-image: url(../images/flags/1x1/cx.svg);
}

.flag-icon-cy {
  background-image: url(../images/flags/4x3/cy.svg);
}
.flag-icon-cy.flag-icon-squared {
  background-image: url(../images/flags/1x1/cy.svg);
}

.flag-icon-cz {
  background-image: url(../images/flags/4x3/cz.svg);
}
.flag-icon-cz.flag-icon-squared {
  background-image: url(../images/flags/1x1/cz.svg);
}

.flag-icon-de {
  background-image: url(../images/flags/4x3/de.svg);
}
.flag-icon-de.flag-icon-squared {
  background-image: url(../images/flags/1x1/de.svg);
}

.flag-icon-dj {
  background-image: url(../images/flags/4x3/dj.svg);
}
.flag-icon-dj.flag-icon-squared {
  background-image: url(../images/flags/1x1/dj.svg);
}

.flag-icon-dk {
  background-image: url(../images/flags/4x3/dk.svg);
}
.flag-icon-dk.flag-icon-squared {
  background-image: url(../images/flags/1x1/dk.svg);
}

.flag-icon-dm {
  background-image: url(../images/flags/4x3/dm.svg);
}
.flag-icon-dm.flag-icon-squared {
  background-image: url(../images/flags/1x1/dm.svg);
}

.flag-icon-do {
  background-image: url(../images/flags/4x3/do.svg);
}
.flag-icon-do.flag-icon-squared {
  background-image: url(../images/flags/1x1/do.svg);
}

.flag-icon-dz {
  background-image: url(../images/flags/4x3/dz.svg);
}
.flag-icon-dz.flag-icon-squared {
  background-image: url(../images/flags/1x1/dz.svg);
}

.flag-icon-ec {
  background-image: url(../images/flags/4x3/ec.svg);
}
.flag-icon-ec.flag-icon-squared {
  background-image: url(../images/flags/1x1/ec.svg);
}

.flag-icon-ee {
  background-image: url(../images/flags/4x3/ee.svg);
}
.flag-icon-ee.flag-icon-squared {
  background-image: url(../images/flags/1x1/ee.svg);
}

.flag-icon-eg {
  background-image: url(../images/flags/4x3/eg.svg);
}
.flag-icon-eg.flag-icon-squared {
  background-image: url(../images/flags/1x1/eg.svg);
}

.flag-icon-eh {
  background-image: url(../images/flags/4x3/eh.svg);
}
.flag-icon-eh.flag-icon-squared {
  background-image: url(../images/flags/1x1/eh.svg);
}

.flag-icon-er {
  background-image: url(../images/flags/4x3/er.svg);
}
.flag-icon-er.flag-icon-squared {
  background-image: url(../images/flags/1x1/er.svg);
}

.flag-icon-es {
  background-image: url(../images/flags/4x3/es.svg);
}
.flag-icon-es.flag-icon-squared {
  background-image: url(../images/flags/1x1/es.svg);
}

.flag-icon-et {
  background-image: url(../images/flags/4x3/et.svg);
}
.flag-icon-et.flag-icon-squared {
  background-image: url(../images/flags/1x1/et.svg);
}

.flag-icon-fi {
  background-image: url(../images/flags/4x3/fi.svg);
}
.flag-icon-fi.flag-icon-squared {
  background-image: url(../images/flags/1x1/fi.svg);
}

.flag-icon-fj {
  background-image: url(../images/flags/4x3/fj.svg);
}
.flag-icon-fj.flag-icon-squared {
  background-image: url(../images/flags/1x1/fj.svg);
}

.flag-icon-fk {
  background-image: url(../images/flags/4x3/fk.svg);
}
.flag-icon-fk.flag-icon-squared {
  background-image: url(../images/flags/1x1/fk.svg);
}

.flag-icon-fm {
  background-image: url(../images/flags/4x3/fm.svg);
}
.flag-icon-fm.flag-icon-squared {
  background-image: url(../images/flags/1x1/fm.svg);
}

.flag-icon-fo {
  background-image: url(../images/flags/4x3/fo.svg);
}
.flag-icon-fo.flag-icon-squared {
  background-image: url(../images/flags/1x1/fo.svg);
}

.flag-icon-fr {
  background-image: url(../images/flags/4x3/fr.svg);
}
.flag-icon-fr.flag-icon-squared {
  background-image: url(../images/flags/1x1/fr.svg);
}

.flag-icon-ga {
  background-image: url(../images/flags/4x3/ga.svg);
}
.flag-icon-ga.flag-icon-squared {
  background-image: url(../images/flags/1x1/ga.svg);
}

.flag-icon-gb {
  background-image: url(../images/flags/4x3/gb.svg);
}
.flag-icon-gb.flag-icon-squared {
  background-image: url(../images/flags/1x1/gb.svg);
}

.flag-icon-gd {
  background-image: url(../images/flags/4x3/gd.svg);
}
.flag-icon-gd.flag-icon-squared {
  background-image: url(../images/flags/1x1/gd.svg);
}

.flag-icon-ge {
  background-image: url(../images/flags/4x3/ge.svg);
}
.flag-icon-ge.flag-icon-squared {
  background-image: url(../images/flags/1x1/ge.svg);
}

.flag-icon-gf {
  background-image: url(../images/flags/4x3/gf.svg);
}
.flag-icon-gf.flag-icon-squared {
  background-image: url(../images/flags/1x1/gf.svg);
}

.flag-icon-gg {
  background-image: url(../images/flags/4x3/gg.svg);
}
.flag-icon-gg.flag-icon-squared {
  background-image: url(../images/flags/1x1/gg.svg);
}

.flag-icon-gh {
  background-image: url(../images/flags/4x3/gh.svg);
}
.flag-icon-gh.flag-icon-squared {
  background-image: url(../images/flags/1x1/gh.svg);
}

.flag-icon-gi {
  background-image: url(../images/flags/4x3/gi.svg);
}
.flag-icon-gi.flag-icon-squared {
  background-image: url(../images/flags/1x1/gi.svg);
}

.flag-icon-gl {
  background-image: url(../images/flags/4x3/gl.svg);
}
.flag-icon-gl.flag-icon-squared {
  background-image: url(../images/flags/1x1/gl.svg);
}

.flag-icon-gm {
  background-image: url(../images/flags/4x3/gm.svg);
}
.flag-icon-gm.flag-icon-squared {
  background-image: url(../images/flags/1x1/gm.svg);
}

.flag-icon-gn {
  background-image: url(../images/flags/4x3/gn.svg);
}
.flag-icon-gn.flag-icon-squared {
  background-image: url(../images/flags/1x1/gn.svg);
}

.flag-icon-gp {
  background-image: url(../images/flags/4x3/gp.svg);
}
.flag-icon-gp.flag-icon-squared {
  background-image: url(../images/flags/1x1/gp.svg);
}

.flag-icon-gq {
  background-image: url(../images/flags/4x3/gq.svg);
}
.flag-icon-gq.flag-icon-squared {
  background-image: url(../images/flags/1x1/gq.svg);
}

.flag-icon-gr {
  background-image: url(../images/flags/4x3/gr.svg);
}
.flag-icon-gr.flag-icon-squared {
  background-image: url(../images/flags/1x1/gr.svg);
}

.flag-icon-gs {
  background-image: url(../images/flags/4x3/gs.svg);
}
.flag-icon-gs.flag-icon-squared {
  background-image: url(../images/flags/1x1/gs.svg);
}

.flag-icon-gt {
  background-image: url(../images/flags/4x3/gt.svg);
}
.flag-icon-gt.flag-icon-squared {
  background-image: url(../images/flags/1x1/gt.svg);
}

.flag-icon-gu {
  background-image: url(../images/flags/4x3/gu.svg);
}
.flag-icon-gu.flag-icon-squared {
  background-image: url(../images/flags/1x1/gu.svg);
}

.flag-icon-gw {
  background-image: url(../images/flags/4x3/gw.svg);
}
.flag-icon-gw.flag-icon-squared {
  background-image: url(../images/flags/1x1/gw.svg);
}

.flag-icon-gy {
  background-image: url(../images/flags/4x3/gy.svg);
}
.flag-icon-gy.flag-icon-squared {
  background-image: url(../images/flags/1x1/gy.svg);
}

.flag-icon-hk {
  background-image: url(../images/flags/4x3/hk.svg);
}
.flag-icon-hk.flag-icon-squared {
  background-image: url(../images/flags/1x1/hk.svg);
}

.flag-icon-hm {
  background-image: url(../images/flags/4x3/hm.svg);
}
.flag-icon-hm.flag-icon-squared {
  background-image: url(../images/flags/1x1/hm.svg);
}

.flag-icon-hn {
  background-image: url(../images/flags/4x3/hn.svg);
}
.flag-icon-hn.flag-icon-squared {
  background-image: url(../images/flags/1x1/hn.svg);
}

.flag-icon-hr {
  background-image: url(../images/flags/4x3/hr.svg);
}
.flag-icon-hr.flag-icon-squared {
  background-image: url(../images/flags/1x1/hr.svg);
}

.flag-icon-ht {
  background-image: url(../images/flags/4x3/ht.svg);
}
.flag-icon-ht.flag-icon-squared {
  background-image: url(../images/flags/1x1/ht.svg);
}

.flag-icon-hu {
  background-image: url(../images/flags/4x3/hu.svg);
}
.flag-icon-hu.flag-icon-squared {
  background-image: url(../images/flags/1x1/hu.svg);
}

.flag-icon-id {
  background-image: url(../images/flags/4x3/id.svg);
}
.flag-icon-id.flag-icon-squared {
  background-image: url(../images/flags/1x1/id.svg);
}

.flag-icon-ie {
  background-image: url(../images/flags/4x3/ie.svg);
}
.flag-icon-ie.flag-icon-squared {
  background-image: url(../images/flags/1x1/ie.svg);
}

.flag-icon-il {
  background-image: url(../images/flags/4x3/il.svg);
}
.flag-icon-il.flag-icon-squared {
  background-image: url(../images/flags/1x1/il.svg);
}

.flag-icon-im {
  background-image: url(../images/flags/4x3/im.svg);
}
.flag-icon-im.flag-icon-squared {
  background-image: url(../images/flags/1x1/im.svg);
}

.flag-icon-in {
  background-image: url(../images/flags/4x3/in.svg);
}
.flag-icon-in.flag-icon-squared {
  background-image: url(../images/flags/1x1/in.svg);
}

.flag-icon-io {
  background-image: url(../images/flags/4x3/io.svg);
}
.flag-icon-io.flag-icon-squared {
  background-image: url(../images/flags/1x1/io.svg);
}

.flag-icon-iq {
  background-image: url(../images/flags/4x3/iq.svg);
}
.flag-icon-iq.flag-icon-squared {
  background-image: url(../images/flags/1x1/iq.svg);
}

.flag-icon-ir {
  background-image: url(../images/flags/4x3/ir.svg);
}
.flag-icon-ir.flag-icon-squared {
  background-image: url(../images/flags/1x1/ir.svg);
}

.flag-icon-is {
  background-image: url(../images/flags/4x3/is.svg);
}
.flag-icon-is.flag-icon-squared {
  background-image: url(../images/flags/1x1/is.svg);
}

.flag-icon-it {
  background-image: url(../images/flags/4x3/it.svg);
}
.flag-icon-it.flag-icon-squared {
  background-image: url(../images/flags/1x1/it.svg);
}

.flag-icon-je {
  background-image: url(../images/flags/4x3/je.svg);
}
.flag-icon-je.flag-icon-squared {
  background-image: url(../images/flags/1x1/je.svg);
}

.flag-icon-jm {
  background-image: url(../images/flags/4x3/jm.svg);
}
.flag-icon-jm.flag-icon-squared {
  background-image: url(../images/flags/1x1/jm.svg);
}

.flag-icon-jo {
  background-image: url(../images/flags/4x3/jo.svg);
}
.flag-icon-jo.flag-icon-squared {
  background-image: url(../images/flags/1x1/jo.svg);
}

.flag-icon-jp {
  background-image: url(../images/flags/4x3/jp.svg);
}
.flag-icon-jp.flag-icon-squared {
  background-image: url(../images/flags/1x1/jp.svg);
}

.flag-icon-ke {
  background-image: url(../images/flags/4x3/ke.svg);
}
.flag-icon-ke.flag-icon-squared {
  background-image: url(../images/flags/1x1/ke.svg);
}

.flag-icon-kg {
  background-image: url(../images/flags/4x3/kg.svg);
}
.flag-icon-kg.flag-icon-squared {
  background-image: url(../images/flags/1x1/kg.svg);
}

.flag-icon-kh {
  background-image: url(../images/flags/4x3/kh.svg);
}
.flag-icon-kh.flag-icon-squared {
  background-image: url(../images/flags/1x1/kh.svg);
}

.flag-icon-ki {
  background-image: url(../images/flags/4x3/ki.svg);
}
.flag-icon-ki.flag-icon-squared {
  background-image: url(../images/flags/1x1/ki.svg);
}

.flag-icon-km {
  background-image: url(../images/flags/4x3/km.svg);
}
.flag-icon-km.flag-icon-squared {
  background-image: url(../images/flags/1x1/km.svg);
}

.flag-icon-kn {
  background-image: url(../images/flags/4x3/kn.svg);
}
.flag-icon-kn.flag-icon-squared {
  background-image: url(../images/flags/1x1/kn.svg);
}

.flag-icon-kp {
  background-image: url(../images/flags/4x3/kp.svg);
}
.flag-icon-kp.flag-icon-squared {
  background-image: url(../images/flags/1x1/kp.svg);
}

.flag-icon-kr {
  background-image: url(../images/flags/4x3/kr.svg);
}
.flag-icon-kr.flag-icon-squared {
  background-image: url(../images/flags/1x1/kr.svg);
}

.flag-icon-kw {
  background-image: url(../images/flags/4x3/kw.svg);
}
.flag-icon-kw.flag-icon-squared {
  background-image: url(../images/flags/1x1/kw.svg);
}

.flag-icon-ky {
  background-image: url(../images/flags/4x3/ky.svg);
}
.flag-icon-ky.flag-icon-squared {
  background-image: url(../images/flags/1x1/ky.svg);
}

.flag-icon-kz {
  background-image: url(../images/flags/4x3/kz.svg);
}
.flag-icon-kz.flag-icon-squared {
  background-image: url(../images/flags/1x1/kz.svg);
}

.flag-icon-la {
  background-image: url(../images/flags/4x3/la.svg);
}
.flag-icon-la.flag-icon-squared {
  background-image: url(../images/flags/1x1/la.svg);
}

.flag-icon-lb {
  background-image: url(../images/flags/4x3/lb.svg);
}
.flag-icon-lb.flag-icon-squared {
  background-image: url(../images/flags/1x1/lb.svg);
}

.flag-icon-lc {
  background-image: url(../images/flags/4x3/lc.svg);
}
.flag-icon-lc.flag-icon-squared {
  background-image: url(../images/flags/1x1/lc.svg);
}

.flag-icon-li {
  background-image: url(../images/flags/4x3/li.svg);
}
.flag-icon-li.flag-icon-squared {
  background-image: url(../images/flags/1x1/li.svg);
}

.flag-icon-lk {
  background-image: url(../images/flags/4x3/lk.svg);
}
.flag-icon-lk.flag-icon-squared {
  background-image: url(../images/flags/1x1/lk.svg);
}

.flag-icon-lr {
  background-image: url(../images/flags/4x3/lr.svg);
}
.flag-icon-lr.flag-icon-squared {
  background-image: url(../images/flags/1x1/lr.svg);
}

.flag-icon-ls {
  background-image: url(../images/flags/4x3/ls.svg);
}
.flag-icon-ls.flag-icon-squared {
  background-image: url(../images/flags/1x1/ls.svg);
}

.flag-icon-lt {
  background-image: url(../images/flags/4x3/lt.svg);
}
.flag-icon-lt.flag-icon-squared {
  background-image: url(../images/flags/1x1/lt.svg);
}

.flag-icon-lu {
  background-image: url(../images/flags/4x3/lu.svg);
}
.flag-icon-lu.flag-icon-squared {
  background-image: url(../images/flags/1x1/lu.svg);
}

.flag-icon-lv {
  background-image: url(../images/flags/4x3/lv.svg);
}
.flag-icon-lv.flag-icon-squared {
  background-image: url(../images/flags/1x1/lv.svg);
}

.flag-icon-ly {
  background-image: url(../images/flags/4x3/ly.svg);
}
.flag-icon-ly.flag-icon-squared {
  background-image: url(../images/flags/1x1/ly.svg);
}

.flag-icon-ma {
  background-image: url(../images/flags/4x3/ma.svg);
}
.flag-icon-ma.flag-icon-squared {
  background-image: url(../images/flags/1x1/ma.svg);
}

.flag-icon-mc {
  background-image: url(../images/flags/4x3/mc.svg);
}
.flag-icon-mc.flag-icon-squared {
  background-image: url(../images/flags/1x1/mc.svg);
}

.flag-icon-md {
  background-image: url(../images/flags/4x3/md.svg);
}
.flag-icon-md.flag-icon-squared {
  background-image: url(../images/flags/1x1/md.svg);
}

.flag-icon-me {
  background-image: url(../images/flags/4x3/me.svg);
}
.flag-icon-me.flag-icon-squared {
  background-image: url(../images/flags/1x1/me.svg);
}

.flag-icon-mf {
  background-image: url(../images/flags/4x3/mf.svg);
}
.flag-icon-mf.flag-icon-squared {
  background-image: url(../images/flags/1x1/mf.svg);
}

.flag-icon-mg {
  background-image: url(../images/flags/4x3/mg.svg);
}
.flag-icon-mg.flag-icon-squared {
  background-image: url(../images/flags/1x1/mg.svg);
}

.flag-icon-mh {
  background-image: url(../images/flags/4x3/mh.svg);
}
.flag-icon-mh.flag-icon-squared {
  background-image: url(../images/flags/1x1/mh.svg);
}

.flag-icon-mk {
  background-image: url(../images/flags/4x3/mk.svg);
}
.flag-icon-mk.flag-icon-squared {
  background-image: url(../images/flags/1x1/mk.svg);
}

.flag-icon-ml {
  background-image: url(../images/flags/4x3/ml.svg);
}
.flag-icon-ml.flag-icon-squared {
  background-image: url(../images/flags/1x1/ml.svg);
}

.flag-icon-mm {
  background-image: url(../images/flags/4x3/mm.svg);
}
.flag-icon-mm.flag-icon-squared {
  background-image: url(../images/flags/1x1/mm.svg);
}

.flag-icon-mn {
  background-image: url(../images/flags/4x3/mn.svg);
}
.flag-icon-mn.flag-icon-squared {
  background-image: url(../images/flags/1x1/mn.svg);
}

.flag-icon-mo {
  background-image: url(../images/flags/4x3/mo.svg);
}
.flag-icon-mo.flag-icon-squared {
  background-image: url(../images/flags/1x1/mo.svg);
}

.flag-icon-mp {
  background-image: url(../images/flags/4x3/mp.svg);
}
.flag-icon-mp.flag-icon-squared {
  background-image: url(../images/flags/1x1/mp.svg);
}

.flag-icon-mq {
  background-image: url(../images/flags/4x3/mq.svg);
}
.flag-icon-mq.flag-icon-squared {
  background-image: url(../images/flags/1x1/mq.svg);
}

.flag-icon-mr {
  background-image: url(../images/flags/4x3/mr.svg);
}
.flag-icon-mr.flag-icon-squared {
  background-image: url(../images/flags/1x1/mr.svg);
}

.flag-icon-ms {
  background-image: url(../images/flags/4x3/ms.svg);
}
.flag-icon-ms.flag-icon-squared {
  background-image: url(../images/flags/1x1/ms.svg);
}

.flag-icon-mt {
  background-image: url(../images/flags/4x3/mt.svg);
}
.flag-icon-mt.flag-icon-squared {
  background-image: url(../images/flags/1x1/mt.svg);
}

.flag-icon-mu {
  background-image: url(../images/flags/4x3/mu.svg);
}
.flag-icon-mu.flag-icon-squared {
  background-image: url(../images/flags/1x1/mu.svg);
}

.flag-icon-mv {
  background-image: url(../images/flags/4x3/mv.svg);
}
.flag-icon-mv.flag-icon-squared {
  background-image: url(../images/flags/1x1/mv.svg);
}

.flag-icon-mw {
  background-image: url(../images/flags/4x3/mw.svg);
}
.flag-icon-mw.flag-icon-squared {
  background-image: url(../images/flags/1x1/mw.svg);
}

.flag-icon-mx {
  background-image: url(../images/flags/4x3/mx.svg);
}
.flag-icon-mx.flag-icon-squared {
  background-image: url(../images/flags/1x1/mx.svg);
}

.flag-icon-my {
  background-image: url(../images/flags/4x3/my.svg);
}
.flag-icon-my.flag-icon-squared {
  background-image: url(../images/flags/1x1/my.svg);
}

.flag-icon-mz {
  background-image: url(../images/flags/4x3/mz.svg);
}
.flag-icon-mz.flag-icon-squared {
  background-image: url(../images/flags/1x1/mz.svg);
}

.flag-icon-na {
  background-image: url(../images/flags/4x3/na.svg);
}
.flag-icon-na.flag-icon-squared {
  background-image: url(../images/flags/1x1/na.svg);
}

.flag-icon-nc {
  background-image: url(../images/flags/4x3/nc.svg);
}
.flag-icon-nc.flag-icon-squared {
  background-image: url(../images/flags/1x1/nc.svg);
}

.flag-icon-ne {
  background-image: url(../images/flags/4x3/ne.svg);
}
.flag-icon-ne.flag-icon-squared {
  background-image: url(../images/flags/1x1/ne.svg);
}

.flag-icon-nf {
  background-image: url(../images/flags/4x3/nf.svg);
}
.flag-icon-nf.flag-icon-squared {
  background-image: url(../images/flags/1x1/nf.svg);
}

.flag-icon-ng {
  background-image: url(../images/flags/4x3/ng.svg);
}
.flag-icon-ng.flag-icon-squared {
  background-image: url(../images/flags/1x1/ng.svg);
}

.flag-icon-ni {
  background-image: url(../images/flags/4x3/ni.svg);
}
.flag-icon-ni.flag-icon-squared {
  background-image: url(../images/flags/1x1/ni.svg);
}

.flag-icon-nl {
  background-image: url(../images/flags/4x3/nl.svg);
}
.flag-icon-nl.flag-icon-squared {
  background-image: url(../images/flags/1x1/nl.svg);
}

.flag-icon-no {
  background-image: url(../images/flags/4x3/no.svg);
}
.flag-icon-no.flag-icon-squared {
  background-image: url(../images/flags/1x1/no.svg);
}

.flag-icon-np {
  background-image: url(../images/flags/4x3/np.svg);
}
.flag-icon-np.flag-icon-squared {
  background-image: url(../images/flags/1x1/np.svg);
}

.flag-icon-nr {
  background-image: url(../images/flags/4x3/nr.svg);
}
.flag-icon-nr.flag-icon-squared {
  background-image: url(../images/flags/1x1/nr.svg);
}

.flag-icon-nu {
  background-image: url(../images/flags/4x3/nu.svg);
}
.flag-icon-nu.flag-icon-squared {
  background-image: url(../images/flags/1x1/nu.svg);
}

.flag-icon-nz {
  background-image: url(../images/flags/4x3/nz.svg);
}
.flag-icon-nz.flag-icon-squared {
  background-image: url(../images/flags/1x1/nz.svg);
}

.flag-icon-om {
  background-image: url(../images/flags/4x3/om.svg);
}
.flag-icon-om.flag-icon-squared {
  background-image: url(../images/flags/1x1/om.svg);
}

.flag-icon-pa {
  background-image: url(../images/flags/4x3/pa.svg);
}
.flag-icon-pa.flag-icon-squared {
  background-image: url(../images/flags/1x1/pa.svg);
}

.flag-icon-pe {
  background-image: url(../images/flags/4x3/pe.svg);
}
.flag-icon-pe.flag-icon-squared {
  background-image: url(../images/flags/1x1/pe.svg);
}

.flag-icon-pf {
  background-image: url(../images/flags/4x3/pf.svg);
}
.flag-icon-pf.flag-icon-squared {
  background-image: url(../images/flags/1x1/pf.svg);
}

.flag-icon-pg {
  background-image: url(../images/flags/4x3/pg.svg);
}
.flag-icon-pg.flag-icon-squared {
  background-image: url(../images/flags/1x1/pg.svg);
}

.flag-icon-ph {
  background-image: url(../images/flags/4x3/ph.svg);
}
.flag-icon-ph.flag-icon-squared {
  background-image: url(../images/flags/1x1/ph.svg);
}

.flag-icon-pk {
  background-image: url(../images/flags/4x3/pk.svg);
}
.flag-icon-pk.flag-icon-squared {
  background-image: url(../images/flags/1x1/pk.svg);
}

.flag-icon-pl {
  background-image: url(../images/flags/4x3/pl.svg);
}
.flag-icon-pl.flag-icon-squared {
  background-image: url(../images/flags/1x1/pl.svg);
}

.flag-icon-pm {
  background-image: url(../images/flags/4x3/pm.svg);
}
.flag-icon-pm.flag-icon-squared {
  background-image: url(../images/flags/1x1/pm.svg);
}

.flag-icon-pn {
  background-image: url(../images/flags/4x3/pn.svg);
}
.flag-icon-pn.flag-icon-squared {
  background-image: url(../images/flags/1x1/pn.svg);
}

.flag-icon-pr {
  background-image: url(../images/flags/4x3/pr.svg);
}
.flag-icon-pr.flag-icon-squared {
  background-image: url(../images/flags/1x1/pr.svg);
}

.flag-icon-ps {
  background-image: url(../images/flags/4x3/ps.svg);
}
.flag-icon-ps.flag-icon-squared {
  background-image: url(../images/flags/1x1/ps.svg);
}

.flag-icon-pt {
  background-image: url(../images/flags/4x3/pt.svg);
}
.flag-icon-pt.flag-icon-squared {
  background-image: url(../images/flags/1x1/pt.svg);
}

.flag-icon-pw {
  background-image: url(../images/flags/4x3/pw.svg);
}
.flag-icon-pw.flag-icon-squared {
  background-image: url(../images/flags/1x1/pw.svg);
}

.flag-icon-py {
  background-image: url(../images/flags/4x3/py.svg);
}
.flag-icon-py.flag-icon-squared {
  background-image: url(../images/flags/1x1/py.svg);
}

.flag-icon-qa {
  background-image: url(../images/flags/4x3/qa.svg);
}
.flag-icon-qa.flag-icon-squared {
  background-image: url(../images/flags/1x1/qa.svg);
}

.flag-icon-re {
  background-image: url(../images/flags/4x3/re.svg);
}
.flag-icon-re.flag-icon-squared {
  background-image: url(../images/flags/1x1/re.svg);
}

.flag-icon-ro {
  background-image: url(../images/flags/4x3/ro.svg);
}
.flag-icon-ro.flag-icon-squared {
  background-image: url(../images/flags/1x1/ro.svg);
}

.flag-icon-rs {
  background-image: url(../images/flags/4x3/rs.svg);
}
.flag-icon-rs.flag-icon-squared {
  background-image: url(../images/flags/1x1/rs.svg);
}

.flag-icon-ru {
  background-image: url(../images/flags/4x3/ru.svg);
}
.flag-icon-ru.flag-icon-squared {
  background-image: url(../images/flags/1x1/ru.svg);
}

.flag-icon-rw {
  background-image: url(../images/flags/4x3/rw.svg);
}
.flag-icon-rw.flag-icon-squared {
  background-image: url(../images/flags/1x1/rw.svg);
}

.flag-icon-sa {
  background-image: url(../images/flags/4x3/sa.svg);
}
.flag-icon-sa.flag-icon-squared {
  background-image: url(../images/flags/1x1/sa.svg);
}

.flag-icon-sb {
  background-image: url(../images/flags/4x3/sb.svg);
}
.flag-icon-sb.flag-icon-squared {
  background-image: url(../images/flags/1x1/sb.svg);
}

.flag-icon-sc {
  background-image: url(../images/flags/4x3/sc.svg);
}
.flag-icon-sc.flag-icon-squared {
  background-image: url(../images/flags/1x1/sc.svg);
}

.flag-icon-sd {
  background-image: url(../images/flags/4x3/sd.svg);
}
.flag-icon-sd.flag-icon-squared {
  background-image: url(../images/flags/1x1/sd.svg);
}

.flag-icon-se {
  background-image: url(../images/flags/4x3/se.svg);
}
.flag-icon-se.flag-icon-squared {
  background-image: url(../images/flags/1x1/se.svg);
}

.flag-icon-sg {
  background-image: url(../images/flags/4x3/sg.svg);
}
.flag-icon-sg.flag-icon-squared {
  background-image: url(../images/flags/1x1/sg.svg);
}

.flag-icon-sh {
  background-image: url(../images/flags/4x3/sh.svg);
}
.flag-icon-sh.flag-icon-squared {
  background-image: url(../images/flags/1x1/sh.svg);
}

.flag-icon-si {
  background-image: url(../images/flags/4x3/si.svg);
}
.flag-icon-si.flag-icon-squared {
  background-image: url(../images/flags/1x1/si.svg);
}

.flag-icon-sj {
  background-image: url(../images/flags/4x3/sj.svg);
}
.flag-icon-sj.flag-icon-squared {
  background-image: url(../images/flags/1x1/sj.svg);
}

.flag-icon-sk {
  background-image: url(../images/flags/4x3/sk.svg);
}
.flag-icon-sk.flag-icon-squared {
  background-image: url(../images/flags/1x1/sk.svg);
}

.flag-icon-sl {
  background-image: url(../images/flags/4x3/sl.svg);
}
.flag-icon-sl.flag-icon-squared {
  background-image: url(../images/flags/1x1/sl.svg);
}

.flag-icon-sm {
  background-image: url(../images/flags/4x3/sm.svg);
}
.flag-icon-sm.flag-icon-squared {
  background-image: url(../images/flags/1x1/sm.svg);
}

.flag-icon-sn {
  background-image: url(../images/flags/4x3/sn.svg);
}
.flag-icon-sn.flag-icon-squared {
  background-image: url(../images/flags/1x1/sn.svg);
}

.flag-icon-so {
  background-image: url(../images/flags/4x3/so.svg);
}
.flag-icon-so.flag-icon-squared {
  background-image: url(../images/flags/1x1/so.svg);
}

.flag-icon-sr {
  background-image: url(../images/flags/4x3/sr.svg);
}
.flag-icon-sr.flag-icon-squared {
  background-image: url(../images/flags/1x1/sr.svg);
}

.flag-icon-ss {
  background-image: url(../images/flags/4x3/ss.svg);
}
.flag-icon-ss.flag-icon-squared {
  background-image: url(../images/flags/1x1/ss.svg);
}

.flag-icon-st {
  background-image: url(../images/flags/4x3/st.svg);
}
.flag-icon-st.flag-icon-squared {
  background-image: url(../images/flags/1x1/st.svg);
}

.flag-icon-sv {
  background-image: url(../images/flags/4x3/sv.svg);
}
.flag-icon-sv.flag-icon-squared {
  background-image: url(../images/flags/1x1/sv.svg);
}

.flag-icon-sx {
  background-image: url(../images/flags/4x3/sx.svg);
}
.flag-icon-sx.flag-icon-squared {
  background-image: url(../images/flags/1x1/sx.svg);
}

.flag-icon-sy {
  background-image: url(../images/flags/4x3/sy.svg);
}
.flag-icon-sy.flag-icon-squared {
  background-image: url(../images/flags/1x1/sy.svg);
}

.flag-icon-sz {
  background-image: url(../images/flags/4x3/sz.svg);
}
.flag-icon-sz.flag-icon-squared {
  background-image: url(../images/flags/1x1/sz.svg);
}

.flag-icon-tc {
  background-image: url(../images/flags/4x3/tc.svg);
}
.flag-icon-tc.flag-icon-squared {
  background-image: url(../images/flags/1x1/tc.svg);
}

.flag-icon-td {
  background-image: url(../images/flags/4x3/td.svg);
}
.flag-icon-td.flag-icon-squared {
  background-image: url(../images/flags/1x1/td.svg);
}

.flag-icon-tf {
  background-image: url(../images/flags/4x3/tf.svg);
}
.flag-icon-tf.flag-icon-squared {
  background-image: url(../images/flags/1x1/tf.svg);
}

.flag-icon-tg {
  background-image: url(../images/flags/4x3/tg.svg);
}
.flag-icon-tg.flag-icon-squared {
  background-image: url(../images/flags/1x1/tg.svg);
}

.flag-icon-th {
  background-image: url(../images/flags/4x3/th.svg);
}
.flag-icon-th.flag-icon-squared {
  background-image: url(../images/flags/1x1/th.svg);
}

.flag-icon-tj {
  background-image: url(../images/flags/4x3/tj.svg);
}
.flag-icon-tj.flag-icon-squared {
  background-image: url(../images/flags/1x1/tj.svg);
}

.flag-icon-tk {
  background-image: url(../images/flags/4x3/tk.svg);
}
.flag-icon-tk.flag-icon-squared {
  background-image: url(../images/flags/1x1/tk.svg);
}

.flag-icon-tl {
  background-image: url(../images/flags/4x3/tl.svg);
}
.flag-icon-tl.flag-icon-squared {
  background-image: url(../images/flags/1x1/tl.svg);
}

.flag-icon-tm {
  background-image: url(../images/flags/4x3/tm.svg);
}
.flag-icon-tm.flag-icon-squared {
  background-image: url(../images/flags/1x1/tm.svg);
}

.flag-icon-tn {
  background-image: url(../images/flags/4x3/tn.svg);
}
.flag-icon-tn.flag-icon-squared {
  background-image: url(../images/flags/1x1/tn.svg);
}

.flag-icon-to {
  background-image: url(../images/flags/4x3/to.svg);
}
.flag-icon-to.flag-icon-squared {
  background-image: url(../images/flags/1x1/to.svg);
}

.flag-icon-tr {
  background-image: url(../images/flags/4x3/tr.svg);
}
.flag-icon-tr.flag-icon-squared {
  background-image: url(../images/flags/1x1/tr.svg);
}

.flag-icon-tt {
  background-image: url(../images/flags/4x3/tt.svg);
}
.flag-icon-tt.flag-icon-squared {
  background-image: url(../images/flags/1x1/tt.svg);
}

.flag-icon-tv {
  background-image: url(../images/flags/4x3/tv.svg);
}
.flag-icon-tv.flag-icon-squared {
  background-image: url(../images/flags/1x1/tv.svg);
}

.flag-icon-tw {
  background-image: url(../images/flags/4x3/tw.svg);
}
.flag-icon-tw.flag-icon-squared {
  background-image: url(../images/flags/1x1/tw.svg);
}

.flag-icon-tz {
  background-image: url(../images/flags/4x3/tz.svg);
}
.flag-icon-tz.flag-icon-squared {
  background-image: url(../images/flags/1x1/tz.svg);
}

.flag-icon-ua {
  background-image: url(../images/flags/4x3/ua.svg);
}
.flag-icon-ua.flag-icon-squared {
  background-image: url(../images/flags/1x1/ua.svg);
}

.flag-icon-ug {
  background-image: url(../images/flags/4x3/ug.svg);
}
.flag-icon-ug.flag-icon-squared {
  background-image: url(../images/flags/1x1/ug.svg);
}

.flag-icon-um {
  background-image: url(../images/flags/4x3/um.svg);
}
.flag-icon-um.flag-icon-squared {
  background-image: url(../images/flags/1x1/um.svg);
}

.flag-icon-us {
  background-image: url(../images/flags/4x3/us.svg);
}
.flag-icon-us.flag-icon-squared {
  background-image: url(../images/flags/1x1/us.svg);
}

.flag-icon-uy {
  background-image: url(../images/flags/4x3/uy.svg);
}
.flag-icon-uy.flag-icon-squared {
  background-image: url(../images/flags/1x1/uy.svg);
}

.flag-icon-uz {
  background-image: url(../images/flags/4x3/uz.svg);
}
.flag-icon-uz.flag-icon-squared {
  background-image: url(../images/flags/1x1/uz.svg);
}

.flag-icon-va {
  background-image: url(../images/flags/4x3/va.svg);
}
.flag-icon-va.flag-icon-squared {
  background-image: url(../images/flags/1x1/va.svg);
}

.flag-icon-vc {
  background-image: url(../images/flags/4x3/vc.svg);
}
.flag-icon-vc.flag-icon-squared {
  background-image: url(../images/flags/1x1/vc.svg);
}

.flag-icon-ve {
  background-image: url(../images/flags/4x3/ve.svg);
}
.flag-icon-ve.flag-icon-squared {
  background-image: url(../images/flags/1x1/ve.svg);
}

.flag-icon-vg {
  background-image: url(../images/flags/4x3/vg.svg);
}
.flag-icon-vg.flag-icon-squared {
  background-image: url(../images/flags/1x1/vg.svg);
}

.flag-icon-vi {
  background-image: url(../images/flags/4x3/vi.svg);
}
.flag-icon-vi.flag-icon-squared {
  background-image: url(../images/flags/1x1/vi.svg);
}

.flag-icon-vn {
  background-image: url(../images/flags/4x3/vn.svg);
}
.flag-icon-vn.flag-icon-squared {
  background-image: url(../images/flags/1x1/vn.svg);
}

.flag-icon-vu {
  background-image: url(../images/flags/4x3/vu.svg);
}
.flag-icon-vu.flag-icon-squared {
  background-image: url(../images/flags/1x1/vu.svg);
}

.flag-icon-wf {
  background-image: url(../images/flags/4x3/wf.svg);
}
.flag-icon-wf.flag-icon-squared {
  background-image: url(../images/flags/1x1/wf.svg);
}

.flag-icon-ws {
  background-image: url(../images/flags/4x3/ws.svg);
}
.flag-icon-ws.flag-icon-squared {
  background-image: url(../images/flags/1x1/ws.svg);
}

.flag-icon-ye {
  background-image: url(../images/flags/4x3/ye.svg);
}
.flag-icon-ye.flag-icon-squared {
  background-image: url(../images/flags/1x1/ye.svg);
}

.flag-icon-yt {
  background-image: url(../images/flags/4x3/yt.svg);
}
.flag-icon-yt.flag-icon-squared {
  background-image: url(../images/flags/1x1/yt.svg);
}

.flag-icon-za {
  background-image: url(../images/flags/4x3/za.svg);
}
.flag-icon-za.flag-icon-squared {
  background-image: url(../images/flags/1x1/za.svg);
}

.flag-icon-zm {
  background-image: url(../images/flags/4x3/zm.svg);
}
.flag-icon-zm.flag-icon-squared {
  background-image: url(../images/flags/1x1/zm.svg);
}

.flag-icon-zw {
  background-image: url(../images/flags/4x3/zw.svg);
}
.flag-icon-zw.flag-icon-squared {
  background-image: url(../images/flags/1x1/zw.svg);
}

.flag-icon-es-ct {
  background-image: url(../images/flags/4x3/es-ct.svg);
}
.flag-icon-es-ct.flag-icon-squared {
  background-image: url(../images/flags/1x1/es-ct.svg);
}

.flag-icon-eu {
  background-image: url(../images/flags/4x3/eu.svg);
}
.flag-icon-eu.flag-icon-squared {
  background-image: url(../images/flags/1x1/eu.svg);
}

.flag-icon-gb-eng {
  background-image: url(../images/flags/4x3/gb-eng.svg);
}
.flag-icon-gb-eng.flag-icon-squared {
  background-image: url(../images/flags/1x1/gb-eng.svg);
}

.flag-icon-gb-nir {
  background-image: url(../images/flags/4x3/gb-nir.svg);
}
.flag-icon-gb-nir.flag-icon-squared {
  background-image: url(../images/flags/1x1/gb-nir.svg);
}

.flag-icon-gb-sct {
  background-image: url(../images/flags/4x3/gb-sct.svg);
}
.flag-icon-gb-sct.flag-icon-squared {
  background-image: url(../images/flags/1x1/gb-sct.svg);
}

.flag-icon-gb-wls {
  background-image: url(../images/flags/4x3/gb-wls.svg);
}
.flag-icon-gb-wls.flag-icon-squared {
  background-image: url(../images/flags/1x1/gb-wls.svg);
}

.flag-icon-un {
  background-image: url(../images/flags/4x3/un.svg);
}
.flag-icon-un.flag-icon-squared {
  background-image: url(../images/flags/1x1/un.svg);
}

.fa.none {
  color: transparent !important;
}

.flag-icon-en {
  background-image: url("../images/flags/4x3/gb.svg");
}

.flag-icon-da {
  background-image: url("../images/flags/4x3/dk.svg");
}

.flag-icon-nn {
  background-image: url("../images/flags/4x3/no.svg");
}

.flag-icon-ic {
  background-image: url("../images/flags/ic.svg");
}

.flag-icon-ea {
  background-image: url("../images/flags/ea.svg");
}

.flag-icon-an {
  background-image: url("../images/flags/an.svg");
}